.header {
    position: fixed;
    height: 100vh;
    width: 100vw;
    overflow: hidden;
    z-index: -1;
  }
  
  #shape {
    position: absolute;
    left: 0;
    bottom: -2vw;
    display: none;
  }
  
 .header img:not(#shape) {
    position: absolute;
    left: 0;
  }
  
    .header img:nth-child(1) {
    opacity: 0.4;
    bottom: -0.1vw;
    animation: move-1 5s infinite;
  }
  
  @keyframes move-1 {
    50% {
      transform: translateY(15px);
    }
  }
  
  .header img:nth-child(2) {
    opacity: 0.3;
    bottom: 0.5vw;
    animation: move-2 4s infinite;
  }
  
  @keyframes move-2 {
    50% {
      transform: translateY(35px);
    }
  }
  
  .header img:nth-child(3) {
    opacity: 0.2;
    bottom: 0.3vw;
    animation: move-3 3.5s infinite;
  }
  
  @keyframes move-3 {
    50% {
      transform: translateY(25px);
    }
  }
  
  .header img:nth-child(4) {
    opacity: 0.3;
    bottom: 0.1vw;
    animation: move-4 3s infinite;
  }
  
  @keyframes move-4 {
    50% {
      transform: translateY(20px);
    }
  }
   